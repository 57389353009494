﻿import {initTabs} from "@/sharedComponents/Tabs"
import {
    $$,
    downloadFileFromBlob,
    get,
    getDataText,
    getFileNameFromResponse,
    postData,
    postFormCustom
} from "@/Utils";
import {validateForm} from "@/Validation";
import {EuronicsResponse} from "@/interfaces";
import {fillPanelAndInitForm} from "@/sharedComponents/Header/CustomerService";

window.addEventListener("load", () => initSass());

function initPanel() {
    get("spare-parts-button")?.addDataBoundEventListener("click", async () => {
        await getDataText(window.config.sparePartsFormUrl, fillPanelAndInitForm);
    });
}

function initDownloadPriceOffer() {
    $$(".download-price-offer")?.forEach(x => x.addDataBoundEventListener("click", () => {
        const incidentId = x.getAttribute("data-incident-id");
        const workOrderId = x.getAttribute("data-repair-work-order-id");
        const priceOfferId = x.getAttribute("data-price-offer-id");
        const reference = x.getAttribute("data-reference");
        const data = { incidentId: incidentId, workOrderId: workOrderId, priceOfferId: priceOfferId, reference: reference};
        const error = x.closest(".price-offer").querySelector("[data-download-price-offer-error]");
        const callback = (resp: Response) => {
            const fileName = getFileNameFromResponse(resp);
            return resp.blob().then(blob => {
                if (blob.type === "text/html") {
                    return blob.text().then(x => {
                        error.innerHTML = x;
                    });
                }
                else {
                    downloadFileFromBlob(blob, fileName);
                    return null;
                }
            });
        }
        postData(window.config.sassDownloadPriceOfferUrl, data, callback);
    }));
}

function initPriceOffers() {
    const callback = (data) => {
        return data.text().then(x => {
            get("_IncidentPriceOffer").parentElement.innerHTML = x;
            initSass();
        });
    }
    
    $$("[data-confirm-price-offer-id]")?.forEach(x => x.addDataBoundEventListener("click", () => {
        const form = x.closest("#price-offer-form") as HTMLFormElement;
        const selectedVariant = x.closest("#price-offer-form").querySelector("input[name='SelectedPriceOfferVariant']:checked") as HTMLElement;
        
        if (!selectedVariant) {
            x.closest("#price-offer-form").querySelector("[data-price-offer-variants-container]").classList.add("input-validation-error");
            return;
        }
        
        const map = new Map<string, string>();
        return postFormCustom(form, map, callback);
    }));

    $$("input[name='SelectedPriceOfferVariant']")?.forEach(x => x.addEventListener("click", () => {
        x.closest("#price-offer-form").querySelector("[data-price-offer-variants-container]").classList.remove("input-validation-error");
    }));
}

function scrollResultsIntoView() {
    const sassResults = document.getElementsByClassName('sass__results');
    if (sassResults.length) {
        sassResults[0].scrollIntoView();
    }
}

function scrollFormErrorIntoView() {
    const formError = document.getElementById('incident-not-found');
    if (formError != null && formError.innerHTML.trim().length > 0) {
        formError.scrollIntoView();
    }
}

function initSass() {
    initTabs();
    initPanel();
    initDownloadPriceOffer();
    initPriceOffers();
    scrollResultsIntoView();
    scrollFormErrorIntoView();

    get("SearchIncident").addDataBoundEventListener("click", () => {
        const form = get("sass-form") as HTMLFormElement;
        if (!validateForm(form))
            return;

        const reference =  (get('Reference') as HTMLInputElement).value.trim()
        const trackingCode= (get('TrackingCode') as HTMLInputElement).value.trim()
        if (reference && trackingCode){
            // We need that behaviour that inputs are always in the URL. Then language changing wont require refilling the form.
            form.action = form.action + '/' + reference + '/' + trackingCode;
        }
        form.submit()
    });
}
